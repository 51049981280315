@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































.v-timeline-item__divider {
  justify-content: left;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: calc(12px - 1px);
  min-width: none;
}
.v-timeline {
  padding-top: 5px;
}
.v-timeline-item {
  padding-bottom: 12px;
}
